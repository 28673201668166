import React, { useContext, useEffect } from "react"
import Img from "gatsby-image"
import Hero from "../configurable/Hero"
import Heading from "../configurable/Heading"
import Block from "../configurable/Block"
import Image from "../configurable/Image"
import Icon from "../olc-framework/Icon"
import HighlightBlock from "../configurable/HighlightBlock"
import Grid from "../configurable/Grid"
import { filterProductsBy, filterProductsByAny, markdownNodesFilter } from "~/utils"
import Col9 from "../grid/Col9"
import Col4 from "../grid/Col4"
import Col8 from "../grid/Col8"
import Col11 from "../grid/Col11"
import Col6 from "../grid/Col6"
import Col7 from "../grid/Col7"
import Col5 from "../grid/Col5"
import TickList from "../configurable/TickList"
import BlockCTA from "../configurable/BlockCTA"
import "../configurable/ProductsAndWarrantiesBlock/styles.scss"
import "../configurable/BlockCTA/styles.scss"
import { CustomerTypeContext } from "~/providers/CustomerTypeProvider"
import Modal from 'react-modal';
import "./new-prods.scss"
import Col3 from "../grid/Col3"
import Section from "../configurable/Section"
import Col12 from "../grid/Col12"
import Col2 from "../grid/Col2"
import FormInput from "../olc-framework/FormInput"
import FormSelect from "../olc-framework/FormSelect"
import { GetModal } from "../configurable/Modal"
import "./bolds.scss"
import Expandable from "../configurable/Expandable"
import "./animation.scss"
import * as StringInv from "~/vectors/string_inv.inline.svg"
import * as MicroInv from "~/vectors/micro_inv.inline.svg"
import * as OptInv from "~/vectors/opt_inv.inline.svg"
import * as HybridInv from "~/vectors/hybrid_inv.inline.svg"
import * as InfogInverter from "~/vectors/infog_inverter.inline.svg"



const content = {
  pvArray: {
    title: 'Solar Panels',
    content: (
      <> 
      <div className="modal_content_container" style={{color:"red"}}>    
        <div className="modal_copy_inverter" >      
          <p>Solar Panels are panels that are typically placed on a household’s roof to absorb energy from the sun. The panels convert  this energy (sunlight) into Direct Current electricity (DC electricity). This DC energy is then fed into the inverter. A number of solar panels working together is referred to as a Solar Array. </p>
        </div>        
        <div className="modal_icon_container">
          <div className="modal_icon" >
          <Icon alias="sun"/>
          </div>  
          <div className="modal_icon_text">
            <p >All our panels come with 25+ years warranty </p>
          </div>
        </div> 
      </div>
      <div className="modal_btn_container">
        {/* <button className="modal_btn">
          <a href='/commercial-products?filter=Panels'>View our panels</a>
        </button> */}
        <button className="modal_btn">
          <a  href='/contact-us'>Ask a question</a>
        </button>
        <button className="modal_btn">
          <a href='/quote'>Get a quote</a>
        </button>
      </div>                  
      </>
    )
  },
  optimiser: {
    title: 'Optimiser',
    content: (
      <>
      <div className="modal_content_container" style={{color:"red"}}>        
        <div className="modal_copy_inverter" >     
            <p>Solar panel optimisers are an additional feature that can be fitted to a standard solar panel system. An optimiser is fitted to each solar panel to optimise the  power output generated by each panel independently.</p>
        </div>
        {/* <div className="modal_icon_container">
          <div>
            <Icon alias="sun" className="modal_icon" />
          </div>
          <div className="modal_icon_text" >
            <p>Lorem ipsum dolor sit amet.</p>
          </div>
        </div> */}
      </div>
      <div className="modal_btn_container">
        {/* <button className="modal_btn">
          <a href='/commercial-products?filter=Other'>Lorem ipsum</a>
        </button> */}
        <button className="modal_btn">
          <a  href='/contact-us'>Ask a question</a>
        </button>
        <button className="modal_btn">
          <a href='/quote'>Get a quote</a>
        </button>
      </div>                  
      </>
    )
  },
  monitoring: {
    title: 'Monitoring',
    content: (
      <>
      <div className="modal_content_container" style={{color:"red"}}>        
        <div className="modal_copy_inverter">  
            <p>
              <strong>Location: </strong>remote, inside or outside property
            </p>
            <p>
            The Photovoltaic (PV) monitoring system collects and analyzes number of parameters being measured in a PV system to monitor and/or evaluate its performance.  </p>
        </div>
      </div>      
      <div className="modal_btn_container" >
         <button className="modal_btn">
           <a  href='/contact-us'>Ask a question</a>
         </button>
         <button className="modal_btn">
           <a href='/quote'>Get a quote</a>
         </button>
       </div>                  
      </>
    )
  },
  battery: {
    title: 'Battery',
    content: (
      <> 
      <div className="modal_content_container" style={{color:"red"}}>       
        <div className="modal_copy_inverter" >      
            <p><strong>Location: </strong>inside or outside property</p>
            <p>
              Batteries are used to store energy generated during the day to be used throughout the night when the system is no longer generating power. Battery technology is quickly developing into a more feasible option for those who primarily use their energy in the evenings. Batteries are particularly beneficial for businesses that require energy throughout the night.
              </p>
        </div>
        <div className="modal_icon_container">
          <div>
            <Icon alias="sun" className="modal_icon" />
          </div>
          <div className="modal_icon_text" >
            <p>All our batteries come with 10+ years warranty </p>
          </div>
        </div>
      </div>
      <div className="modal_btn_container">
        {/* <button className="modal_btn">
          <a href='/commercial-products?filter=Batteries'>View our batteries</a>
        </button> */}
        <button className="modal_btn">
          <a  href='/contact-us'>Ask a question</a>
        </button>
        <button className="modal_btn">
          <a href='/quote'>Get a quote</a>
        </button>
      </div>                  
      </>
    )
  },
  inverter: {
    title: 'Inverter',
    content: (
      <> 
      <div className="modal_content_container" style={{color:"red"}}>       
        <div className="modal_copy_inverter" >  
            <p><strong>Location: </strong>inside or outside property</p>
            <p>Inverters are a crucial part of any solar energy sydghfstem. Their purpose is to convert the Direct Currect (DC) electricity that the solar panels produce into 240V Alternating Current (AC) electricity, which is what powers everything in your property. The inverter is a hardworking piece of equipment that works constantly throughout the lifetime of your system – so it tends to be the piece most likely to have faults. This means they usually only have a warranty of around 10 years. For this reason, we will always recommend that you choose a high quality inverter that is easily serviceable such as Sungrow or SolarEdge.</p>
        </div>
        <div className="modal_icon_container">
          <div>
            <Icon alias="sun" className="modal_icon" />
          </div>
          <div className="modal_icon_text" >
            <p>All our inverters come with 10+ years warranty </p>
          </div>
        </div>     
      </div>       
      <div className="modal_btn_container">
        {/* <button className="modal_btn">
          <a href='#productsGrid'>View our inverters</a>
        </button> */}
        <button className="modal_btn">
          <a  href='/contact-us'>Ask a question</a>
        </button>
        <button className="modal_btn">
          <a href='/quote'>Get a quote</a>
        </button>
      </div>                  
      </>
    )
  },
  grid: {
    title: 'National grid',
    content: (
      <>     
      <div className="modal_content_container" style={{color:"red"}}>   
        <div className="modal_copy_inverter" >      
          <p>The Electricity Grid is a public electricity source which is fuelled through fuels, wind, water and solar. Every UK region has electricity distribution networks who own the power lines and poles that transfers the electricity. Energy retailers buy this electricity at wholesale prices and resells it to households and businesses, which is what our electricity bills are.
          </p>
          <p>            
          A grid connected solar system has the ability to pull energy from the grid when the solar system is not generating enough energy or feed excess energy generated to the grid. In the instance a solar system feeds the grid excess energy, the household will receive a credit on their electricity bill. This is called an export tariff. You need to have approval from your electricity distributor to connect a solar system to the grid, however the solar installer will arrange this.
          </p>
        </div>
      </div>
      <div className="modal_btn_container">
        <button className="modal_btn">
          <a  href='/contact-us'>Ask a question</a>
        </button>
        <button className="modal_btn">
          <a href='/quote'>Get a quote</a>
        </button>
      </div>                  
      </>
    )
  },
  grid_meter: {
    title: 'Grid meter',
    content: (
      <>   
      <div className="modal_content_container" style={{color:"red"}}>     
        <div className="modal_copy_inverter" >  
            <p>A smart meter is an electronic device that records information such as consumption of grid electric energy, voltage levels, current, and power factor.</p>
        </div>
        {/* <div className="modal_icon_container">
          <div>
            <Icon alias="sun" className="modal_icon" />
          </div>
          <div className="modal_icon_text" >
            <p>All our EV Chargers come with 5+ years warranty </p>
          </div>
        </div> */}
      </div>
      <div className="modal_btn_container">
        <button className="modal_btn">
          <a  href='/contact-us'>Ask a question</a>
        </button>
        <button className="modal_btn">
          <a href='/quote'>Get a quote</a>
        </button>
      </div>                  
      </>
    )
  },
  property: {
    title: 'AC load for your property',
    content: (
      <>  
      <div className="modal_content_container" style={{color:"red"}}>      
        <div className="modal_copy_inverter" >     
          <p>Your facilities will consume the solar energy generated by your PV system. We recommend a system designed around your property consumption in order to get the most out of your money. </p>
        </div>
      </div>
      <div className="modal_btn_container">
        <button className="modal_btn">
          <a  href='/contact-us'>Ask a question</a>
        </button>
        <button className="modal_btn">
          <a href='/quote'>Get a quote</a>
        </button>
      </div>                  
      </>
    )
  },  
  brand1: {
    title: (config) => config.title,
    content: (onViewAll, config) => (
      <>    
      <div className="modal_content_container" >    
        <div className="modal_copy_inverter" >      
            <p><strong>Manufacturing Country: </strong>{config.manufaturer}</p>
            <p>{config.content}</p>
        </div>
        {/* <div className="modal_icon_container">
          <div>
            <Icon alias="sun" className="modal_icon" />
          </div>
          <div className="modal_icon_text" >
            <p>{config.fact}</p>
          </div>
        </div>  */}
      </div>
      <div className="modal_btn_container">
        <button className="modal_btn" onClick={() => onViewAll(config.brand)}>
          <a href='#productsGrid'>View all brand products</a>
        </button>
        <button className="modal_btn">
          <a  href='/contact-us'>Ask a question</a>
        </button>
        <button className="modal_btn">
          <a href='/quote'>Get a quote</a>
        </button>
      </div>                  
      </>
    )
  },
  
}

const brandModalConfigs = {
  sofar: {
    manufaturer: 'China',
    content: 'Sofarsolar is a leading solar&hybird inverter manufacturer in China. It ranges from 1KW to 255KW, which can fully meet the requirements of residential, ',
    fact: 'sofar is great',
    brand: 'Sofar',
    title: 'Sofar'
  },
  foxess: {
    manufaturer: 'China',
    content: "Engineered by some of the world's leading inverter and battery experts, our products are breaking new ground; offering customers the most advanced product features currently available, coupled with unrivalled performance and reliability.",
    fact: 'foxess is great',
    brand: 'Fox ESS',
    title: 'Fox Ess'
  },
  huawei: {
    manufaturer: 'China',
    content: "Huawei is a leading global provider of information and communications technology (ICT) infrastructure and smart devices.    ",
    fact: 'huawei is great',
    brand: 'Huawei',
    title: 'Huawei'
  },
  solis: {
    manufaturer: 'USA',
    content: 'Solis Energy, Inc. is a manufacturer of outdoor power systems for the industrial automation and control, transportation, telecommunication, security, and government markets. ',
    fact: 'solis is great',
    brand: 'Solis',
    title: 'Solis'
  },
  solaredge: {
    manufaturer: 'Israel',
    content: 'SolarEdge Technologies, Inc. is an Israel-headquartered provider of power optimizer, solar inverter and monitoring systems for photovoltaic arrays. These products aim to increase energy output through module-level Maximum Power Point Tracking.',
    fact: 'solaredge is great',
    brand: 'SolarEdge',
    title: 'Solar Edge'
  },

}


const invertersInfo = ({ markdownNodes }) => {
  const productsBlockRef = React.createRef() as React.RefObject<HTMLElement>

  const { customerType, setCustomerType } = useContext(CustomerTypeContext);

  const isDomestic = React.useMemo(() => customerType === "domestic", [customerType]);

  const [hasUsedFilters, setHasUsedFilters] = React.useState(true);

  //START FILTER LOGIC

  const productsWarranties = markdownNodesFilter(
    markdownNodes,
    "products_and_warranties"
  )

  const [brandFilter, setBrandFilter] = React.useState<string>('all');
  const [sizeFilter, setSizeFilter] = React.useState<string>('all');
  const [searchFilter, setSearchFilter] = React.useState<string>(''); 

  const inverters = filterProductsBy(productsWarranties, 'category', 'inverters');

  const [filteredProducts, setFilteredProducts] = React.useState<typeof inverters>(inverters);

  useEffect(() => {

    let filteredProducts = inverters;

    if (brandFilter !== 'all') {
      filteredProducts = filterProductsBy(filteredProducts, "brand", brandFilter);
    }

    if (sizeFilter !== 'all') {
      filteredProducts = filterProductsBy(filteredProducts, "size", sizeFilter);
    }

    if (searchFilter !== '') {
      filteredProducts = filterProductsByAny(filteredProducts, ["brand", "size", "title", "description", "keywords"], searchFilter);
    }

    if (filteredProducts !== inverters) {
      setHasUsedFilters(true);
    }
        
    setFilteredProducts(filteredProducts);

  }, [brandFilter, sizeFilter, searchFilter])

  const onFilterChange = (key: string, value: string) => {
    switch(key) {
      case "brand": 
        setBrandFilter(value);
      break;
      case "size": 
        setSizeFilter(value);
      break;
      case "search": 
        setSearchFilter(value); 
      break;
    }
  }

  //console.log("hasUsedFilters", hasUsedFilters)
  const showProducts = hasUsedFilters;

  //END FILTER LOGIC



  //START MODAL LOGIC

  let subtitle;

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f000'; 
  }

  const [modalContent, setModalContent] = React.useState(undefined);

  const [brandModalConfig, setBrandModalConfig] = React.useState(undefined);
  
  const closeModal = React.useCallback(() => {
    setModalContent(undefined);
  }, []);

  const onOpenModalFor = React.useCallback((name: string) => {
    setModalContent(content[name]);
  }, []);



  const getModalContent = () => {
    if (modalContent?.content) {
      return typeof modalContent?.content === 'function' ? modalContent?.content((brand) => {
        setBrandFilter(brand);
        closeModal();        
      }, brandModalConfig) : modalContent?.content
    }
    return modalContent?.content;
  }

  const getModalTitle = () => {
    if (modalContent?.title) {
      return typeof modalContent?.title === 'function' ? modalContent?.title(brandModalConfig) : modalContent?.title;
    }
    return '';
  }

  //END MODAL LOGIC

  return (
    <div>
      <GetModal
          show={Boolean(modalContent)}
          onClose={closeModal}
          title={getModalTitle()}
        >
          {getModalContent()}
      </GetModal>      
      
      <Hero imageUrl="/images/_breadcrumb21.png" compact>
        <Heading level={1} underlined>
          Our Products
        </Heading>
      </Hero>
      <Block>
      <Section className="introSec" >
          <div className="container" >
            <Col11>
              <Heading level={1} >Solar Inverters</Heading>
            </Col11>
            <div className="row bold-formatting">
              <Col11>
                <p>
                At Green Energy Together, we believe the future of energy is green, renewable, ethical and fair-priced and our transparent approach places us well to deliver the future, now. We have the ideal solution to <strong>maximise the energy generated</strong> for every type of installation from domestic dwellings, small/large offices, commercial buildings to industrial units, manufacturing plants and solar farms.
                </p>
                <p>
                Using a variety of brands from <strong>world-leading manufacturers</strong> allows us to offer tailored solutions, to meet the individual needs of each of our customers. As well as considering factors such as <strong>budget, current energy usage and installation environment</strong>. Below we will explain everything you need to know about an inverter, how the currents work and our brands.
                </p>
              </Col11>
            </div>
            {/* <div className="row circlesArea">
              <Col2>
                <div className="circlesBox">
                  <div className="circle" >
                    <p>25</p>
                  </div>
                </div>
                <p className="circleCaption">
                  Linear<br />warranty
                </p>
              </Col2>
              <Col2>
                <div className="circlesBox">
                  <div className="circle" >
                    <p>12</p>
                  </div>
                </div>
                <p className="circleCaption">
                  Product<br />warranty
                </p>
              </Col2>
              <Col2>
                <div className="circlesBox">
                  <div className="circle" >
                    <p>2</p>
                  </div>
                </div>
                <p className="circleCaption">
                  Workmanship<br />warranty
                </p>
              </Col2>
            </div> */}


          </div>
        </Section>

        <Section className="schematicSec" >
          <div className="container" style={{ marginLeft: 0 }}>
            <Col11>
              <Heading level={2} underlined>Currents in a nutshell</Heading>
            </Col11>
            <div className="row">
              <Col11>
                <p className="bold-formatting">
                An inverter is a critical part of any Solar PV system acting <strong>as the brain</strong> the inverter collects the power generated by the solar panels known as Direct Current (DC). The invertor then converters the DC electricity into 240v single phase or 400v three-phase Alternating Current (AC) electricity.
                </p>
              </Col11>
            </div>
          </div>
          <div className="row" >
          <Col11>
            <div className="infocontainer hidden-xs" >
              <InfogInverter/>
              {/* <button className="btn btn_i_array" onClick={() => onOpenModalFor('pvArray')}>Solar <br/>Panels</button> 
              <button className="btn btn_i_monitoring" onClick={() => onOpenModalFor('monitoring')}>Monitoring</button>
              <button className="btn btn_i_battery" onClick={() => onOpenModalFor('battery')}>Battery</button>
              <button className="btn btn_i_inverter" onClick={() => onOpenModalFor('inverter')}>Inverter</button>
              <button className="btn btn_i_grid" onClick={() => onOpenModalFor('grid')}>Grid</button>
              <button className="btn btn_i_grid_meter" onClick={() => onOpenModalFor('grid_meter')}>Grid<br/>meter</button>
              <button className="btn btn_i_property" onClick={() => onOpenModalFor('property')}>AC Load</button>
              <button className="btn btn_i_optimiser" onClick={() => onOpenModalFor('optimiser')}>Optimiser</button> */}
            </div> 

            <div className="infocontainer visible-xs">
            <InfogInverter/>
              {/* <button className="btn btn_i_array" style={{padding:'2px 5px'}}  onClick={() => onOpenModalFor('pvArray')}>+</button> 
              <button className="btn btn_i_monitoring" style={{padding:'2px 5px'}}  onClick={() => onOpenModalFor('monitoring')}>+</button>
              <button className="btn btn_i_battery" style={{padding:'2px 5px'}}  onClick={() => onOpenModalFor('battery')}>+</button>
              <button className="btn btn_i_inverter" style={{padding:'2px 5px'}}  onClick={() => onOpenModalFor('inverter')}>+</button>
              <button className="btn btn_i_grid" style={{padding:'2px 5px'}}  onClick={() => onOpenModalFor('grid')}>+</button>
              <button className="btn btn_i_grid_meter" style={{padding:'2px 5px'}}  onClick={() => onOpenModalFor('grid_meter')}>+</button>
              <button className="btn btn_i_property" style={{padding:'2px 5px'}}  onClick={() => onOpenModalFor('property')}>+</button>
              <button className="btn btn_i_optimiser" style={{padding:'2px 5px'}}  onClick={() => onOpenModalFor('optimiser')}>+</button> */}
            </div>             
          </Col11>
        </div>
        </Section>

        <Section className="inverter-tech" >
          <div className="container" >
            <Col11>
              <Heading level={2} underlined>Types of Inverter Technology</Heading>
            </Col11>
            <div className="row bold-formatting">
              <Col11>
                <p>
                Our designs are cost-effective because we only sell you what your <strong>lifestyle needs</strong>. When it comes to your system we make use of different inverter technologies to allow us to tailor your free design to your needs and environment. These technologies include:
                </p>
              </Col11>
            </div>
            <div className="tech-type" >
              <div className="row">
                <Col11>
                  <Heading level={3}>String Inverters</Heading>
                </Col11>
              </div>
              <div className="row illus-row">
                <Col7>
                <p>
                String inverters are the standard type of inverter used for Solar PV installations; solar panels are daisy-chained together with inverter collecting the power from the string, with inverters cable of monitoring multiple strings or MPPT. This is illustrated in the image to the right.
                </p><p>
                In a string system, shading can be an issue as if the lowest-performing panel in the string is at 89% efficiency, the whole string will perform at 89% efficiency. If your roof has a lot of shading, an optimised inverter would be recommended by our designers. </p>
                </Col7>
                <Col4 style={{alignSelf:"none"}}>
                  <StringInv />
                </Col4>
              </div>
            </div>
            <div className="tech-type" >
              <div className="row">
                <Col11>
                  <Heading level={3}>Optimised Inverters</Heading>
                </Col11>
              </div>
              <div className="row illus-row">

                <Col7>
                  <p className="bold-formatting">
                  Shading can have a massive impact on the efficiency of a solar panel.  However, when using an optimised inverter each solar panel is connected to an Optimiser.  In a commercial setting, it’s usually two panels to one optimiser. This allows an Optimised Inverter to monitor and collect the <strong>energy from each panel</strong> in an MPPT. </p><p  className="bold-formatting">
              To see our optimised inverters <a href="https://get-uk.com/inverters-info"> click here</a>.

                  </p>
                </Col7>
                <Col4 style={{alignSelf:"none"}}>
                  <OptInv />
                </Col4>
              </div>
              <Expandable
                  readmore={
                    <>
                      <div className="container visible-xs" style={{ height: 40 }} />
                        <div className="row ">
                          <Col11>
                          <div style={{marginTop: 30}} >
                          <Heading level={4}>Health & Safety Features</Heading>
                          </div>
                            <p>
                            Prestigious brands such as SolarEdge & Huawei. These brands  offer additional functionality to meet certain requirements such as
                            </p>
                          </Col11>
                        </div>
                        <div className="row" style={{paddingTop:30}}>

                      <Col5>
                        <TickList nolines blueticks>
                          <li>Strings installed in different orientations to be connected to the same inverter</li>
                          <li>Integration with a buildings fire alarm & detection system for DC isolation – Firefighter Gateway</li>
                        </TickList>
                      </Col5>
                      <Col5>
                        <TickList nolines blueticks>                    
                          <li>Health & Safety, arc fault monitoring and DC shutdown, with each optimiser isolating the DC charge from their panel</li>
                        </TickList>
                      </Col5>
                      </div>
                    </>
                  }
                />


            </div>

            <div className="tech-type" style={{paddingTop:100}} >

              <div className="row illus-row">

                <Col7>
                <Heading level={3}>Micro Inverter</Heading>
                <p className="bold-formatting">
                Like an optimized system, each panel is connected to their own individual micro-inverter, which allows for <strong>individual monitoring</strong> of each panel and is an ideal solution where there isn’t room to install a typical inverter
                </p>
                </Col7>
                <Col4 style={{alignSelf:"none"}}>
                  <MicroInv />
                </Col4>
              </div>
            </div>
            <div className="tech-type" >

              <div className="row illus-row">

                <Col7>
                <Heading level={3}>Hybrid Inverters</Heading>
                <p>
                A hybrid inverter is the combination of an on-grid and off-grid solar inverter. This inverter can control the solar panel arrays; for storing batteries and power grids, simultaneously.  
                </p>
                </Col7>
                <Col4 style={{alignSelf:"none"}}>
                  <HybridInv/>
                </Col4>

              </div>
              </div>
          </div>
        </Section>


        <Section className="prodsSec" id="products" >
          <div className="container" >
            <Col11>
              <Heading level={2} underlined>The perfect inverter for your needs</Heading>
            </Col11>
            <div className="row">
              <Col11>
                <p >
                Here is our collection of inverters with technical specifications including performance, technical characteristics and warranty. For further information, get in touch with one of our advisors.</p>
              </Col11>
            </div>
            <div className=" brands row" >

              <button>
                <img src={require('../../images/brands/sofar.png')} alt="my image"  onClick={() => {
                  setBrandModalConfig(brandModalConfigs.sofar);
                  onOpenModalFor('brand1');
                }} />
              </button>
              <button>
                <img src={require('../../images/brands/foxess.png')} alt="my image"  onClick={() => {
                  setBrandModalConfig(brandModalConfigs.foxess);
                  onOpenModalFor('brand1');
                }} />
              </button>
              <button>
                <img src={require('../../images/brands/huawei.png')} alt="my image" onClick={() => {
                  setBrandModalConfig(brandModalConfigs.huawei);
                  onOpenModalFor('brand1');
                }} />
              </button>
              <button>
                <img src={require('../../images/brands/solis.png')} alt="my image"  onClick={() => {
                  setBrandModalConfig(brandModalConfigs.solis);
                  onOpenModalFor('brand1');
                }} />
              </button>
              <button>
                <img src={require('../../images/brands/solaredge.png')} alt="my image"  onClick={() => {
                  setBrandModalConfig(brandModalConfigs.solaredge);
                  onOpenModalFor('brand1');
                }} />
              </button>



            </div>
            
          </div>

          <div className="row fieldsArea">
            <Col4>
              <FormSelect 
                name="brand"
                label="Filter by brand"
                options={["all", "Fox ESS", "Sofar", "Huawei", "SolarEdge", "Solis"]}
                value={brandFilter}
                onChange={(evt) => { onFilterChange('brand', evt.target.value); }}
              />
            </Col4>
            <Col4>
              <FormSelect 
                name="size"
                label="Filter by size"
                options={["all", "1kW to 5kW", "5kW to 10kW", "10kW to 20kW", "20kW to 50kW", "50kW+"]}
                value={sizeFilter}
                onChange={(evt) => { onFilterChange('size', evt.target.value); }}
              />
            </Col4>      
            <Col4>
              <FormInput
                name="keyword"
                label="Search for a product "
                placeholder="Start typing"
                value={searchFilter}
                onChange={(evt) => { onFilterChange('search', evt.currentTarget.value); }}
              />
            </Col4>
          </div>

       
        {showProducts && (
          <Grid id='productsGrid'>
                  {filteredProducts.map(item => {
                    const pwItem = item.frontmatter
                    return (
                      <li key={item.fields.slug}>
                        {pwItem.image?.childImageSharp && (
                          <Img
                            fluid={pwItem.image.childImageSharp.fluid}
                            alt={pwItem.title}
                          />
                        )}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "top",
                            marginTop: 24,
                          }}
                        >
                          <p className="grid-item__title">{pwItem.title}</p>
                          {pwItem.pdf?.publicURL && (
                            <>
                              <a
                                href={pwItem.pdf.publicURL}
                                target="_blank"
                                style={{
                                  display: "block",
                                  marginTop: 0,
                                  marginLeft: 24,
                                }}
                              >
                                <div
                                  className="icon__circle-wrapper"
                                  style={{ fontSize: 32, color: "#70b33b" }}
                                >
                                  <Icon
                                    alias="pdf"
                                    className="grid__icon u-styling--box-shadow"
                                  />
                                </div>
                              </a>
                            </>
                          )}
                        </div>
                      </li>
                    )
                  })}
          </Grid>
        )}
        </Section>
        

      </Block>
    </div>
  )
}

export default invertersInfo
